import React from "react"
import SocialLinks from "./SocialLinks"
const Footer = () => {
  return (
    <div className="pt-4">
      {" "}
      <div className="flex items-center justify-center py-3 bg-green-50 my-2">
        <div>
          <div>
            {" "}
            <span className="text-xl font-medium underline">
              {" "}
              Quick Reservation{" "}
            </span>
          </div>{" "}
          <div className="lg:flex lg:flex-row lg:items-center lg:text-xl">
            <div className="lg:pr-3">
              <span className="font-bold"> Phone : </span>
              <span>+250 788 383 385</span>
            </div>
            <div className="lg:px-3">
              <span className="font-bold"> Email: </span>
              <span>kivuhilltopview@gmail.com</span>
            </div>
          </div>
        </div>
      </div>
      <div class=" bg-gray-50 flex py-3  border-b-2 px-12 items-center justify-between  sm:flex-wrap-reverse flex-col lg:flex-row">
        <span>
          {" "}
          &copy; 2022 Kivu hilltop View Hotel & Resort . All Rights Reserved
        </span>

        <SocialLinks />
      </div>
    </div>
  )
}

export default Footer
