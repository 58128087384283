import React from "react"

import NavItem from "./NavItem"
const NavList = ({ routeRefs }) => {
  const [visitedLink, setVisited] = React.useState(null)
  const links = [
    {
      id: 1,
      label: "stay",
      node: routeRefs?.stayRef,
      fallback: "/#stay",
    },
    {
      id: 2,
      label: "restaurant",
      node: routeRefs?.dinningRef,
      fallback: "/#dinning",
    },
    {
      id: 3,
      label: "Things To do",
      node: routeRefs?.activitiesRef,
      fallback: "/#activities",
    },
    {
      id: 4,
      label: "gallery",
      node: "/gallery",
      node: routeRefs?.exploreRef,
      fallback: "/#explore",
    },
    {
      id: 5,
      label: "contact",
      node: routeRefs?.contactRef,
      fallback: "/#contact",
    },
  ]

  const onVisited = ({ id, node, fallback }) => {
    setVisited(id)
    if (node?.current) {
      node.current.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <ul className="flex flex-1 justify-between align-center">
      {links.map(link => (
        <NavItem
          key={link.id}
          {...link}
          onVisited={id => onVisited(link)}
          visited={link.id === visitedLink}
          isNode={Boolean(link.node?.current && typeof link.node !== "string")}
        />
      ))}
    </ul>
  )
}

export default NavList
